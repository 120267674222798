
import {whoami} from '../../../auto/js/users/UserInfo';
import { RIBBON_MENU } from '../../../auto/js/metadata/MenuType';

import { displayRegistreAffectationList } from '../../../auto/js/lists/registreAffectation/RegistreAffectationList';
import { displayCaseDataList } from '../../../auto/js/lists/caseData/CaseDataList';
import { displayNewDebtOrderForm } from '../../../auto/js/forms/debtOrder/NewDebtOrderForm';
import { displayTranslationsList } from '../../../auto/js/lists/metadata/TranslationsList';
import { displayNewTranslationsForm } from '../../../auto/js/forms/metadata/TranslationsForm';


export const menu = () => {
    return {
			/* "raf": {
					"raf": {
						submenu: {
							"actions": {
								options: {
								},
								label: "Actions"
							}
						},
						do: (gui) => displayRegistreAffectationList(gui),
						label: "Registre d'affectation"
					},
				label: "Registre d'affectation",
				layout: 'col-4'
			} 
		,*/
		"na": {
				"na": {
					submenu: {
						"actions": {
							options: {
								 new: { label: "New", do: (gui) => displayNewDebtOrderForm(gui) },
							},
							label: "Actions"
						}
					},
					do: (gui) => displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["SUBMITTED"]}),
					label: "New Cases"
				},
			label: "New Cases",
			layout: 'col-4'
		}
		,
		"ac": {
				"ac": {
					submenu: {
						"actions": {
							options: {
							},
							label: "Actions"
						}
					},
					do: (gui) => displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["CLOSED"]}),
					label: "Refused Cases"
				},
			label: "Refused Cases",
			layout: 'col-4'
		}
		,
		"aj": {
				"aj": {
					submenu: {
						"actions": {
							options: {
							},
							label: "Actions"
						}
					},
					do: (gui) => displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["JUDGED"]}),
					label: "Accepted Cases"
				},
			label: "Accepted Cases",
			layout: 'col-4'
		}
		,
		"admin" : {
			"crvs-admin": {
					submenu: {
						"actions": {
							options: {
								new: {label: "New", do: (gui) => displayNewTranslationsForm(gui)},
							},
							label: "Actions"
						}
					},
					do: (gui) => displayTranslationsList(gui),
					label: "Translations"
				},
			label: "Site Configurations",
			layout: 'col-4'
		}
				

	}
}
export const menuType = RIBBON_MENU;
