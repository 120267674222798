import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { grey } from "@mui/material/colors";
import { displayCaseDataTimeline } from "../../timelines/CaseDataTimeline";

export const getOrderBy = () => {
    return "ID"
}

export const getRowActions = (rowData) => {
    return (
        <Tooltip>
            <IconButton 
                onClick={(event) => {
                    displayCaseDataTimeline(rowData.gui, rowData.id)
                }}
                size="small"
                sx={{color: grey[900]}}
            >
                <VisibilityIcon />
            </IconButton>
        </Tooltip>
    )
}

export const getErrorList = () => {
    return [];
}

export const filterDataLogic = (rows) => {
    let newRows = [];
    for (let i in rows) {
        let row = rows[i];
        let creditor = '';
        let debtor = '';
        if (row.creditor.length) {
            let pFirst = true;
            row.creditor.forEach(element => {
                if (pFirst) {
                    creditor = element.firstname;
                    pFirst = false;
                } 
                else {
                    creditor = creditor.concat(', ', element.firstname)
                }
                if (element.lastname != null) 
                    creditor = creditor.concat(' ', element.lastname)
            });
        }

        if (row.debtor.length) {
            let vFirst = true;
            row.debtor.forEach(element => {
                if (vFirst) {
                    debtor = element.firstname;
                    vFirst = false;
                } 
                else {
                    debtor = debtor.concat(', ', element.firstname)
                }
                if (element.lastname != null) 
                    debtor = debtor.concat(' ', element.lastname)
            });
        }
        
        row.creditor = creditor;
        row.debtor = debtor;
        newRows.push(row);
    };
    return newRows;
}