import React from "react";
import {v4 as uuidv4} from 'uuid';
import _ from 'lodash';
import { CAN_I_SWITCH } from "../../../../auto/js/events/Gui";
import { pojoMetadata, getServiceUri } from "../../../../auto/js/metadata";
import { rest, t } from "../../../../auto/js/services";
import { showNotification, swapObject } from "../../../../auto/js/utils";
import { AlertDialog } from "../../../../auto/js/widgets/Dialogs";
import { createFormComponent } from "../../../../auto/js/widgets/FormComponent";
import { customDebtOrderForm2Dto, customDebtOrderDto2Form, getContext } from "../../../../main/js/forms/debtOrder/DebtOrderFormCommon";
import * as Yup from 'yup';
import {
    idDisplay,
	debtorComponentHook,
	creditorComponentHook,
	debtInformationComponentHook
} from "../../../../main/js/forms/debtOrder/DebtOrderFormCommon";


export const debtOrderFields = [
    {
        name: "id",
        label: "id",
        type: "number",
        x: "1",
        y: "1",
        layout: "col-md-6",
        display: (data) => idDisplay(data),
    },
    {
        name: "debtor",
        label: "debtor",
        type: "custom",
        x: "2",
        y: "2",
        layout: "col-md-6",
        component: (name, disabled) => debtorComponentHook(name, disabled),
    },
    {
        name: "creditor",
        label: "creditor",
        type: "custom",
        x: "1",
        y: "2",
        layout: "col-md-6",
        component: (name, disabled) => creditorComponentHook(name, disabled),
    },
    {
        name: "debtInformation",
        label: "debtInformation",
        type: "custom",
        x: "1",
        y: "4",
        layout: "col-md-12",
        component: (name, disabled) => debtInformationComponentHook(name, disabled),
    },
    {
        name: "amountOwed",
        label: "amountOwed",
        type: "text",
        x: "1",
        y: "5",
        layout: "col-md-6",
    },
    {
        name: "attachments",
        label: "attachments",
        type: "file",
        x: "1",
        y: "6",
        layout: "col-md-12",
        uploadUrl: (id) => getDebtOrderAttachmentsUploadUrl(id),
        previewUrl: (id) => getDebtOrderAttachmentsPreviewUrl(id),
        loadData: async (id) => await loadDebtOrderAttachmentsData(id),
        handleDelete:(id) => handleDebtOrderAttachmentsDelete(id),
        handleClick: (id) => handleDebtOrderAttachmentsClick(id),
        updateFileData: (data) => updateDebtOrderAttachmentsData(data),
    }
]

const getDebtOrderAttachmentsUploadUrl = (id) => {
    return getServiceUri() + 'debt-order/attachments' + '/' + id;
};
const handleDebtOrderAttachmentsClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'debt-order/attachments' + '/' + id + '/' + token;
};
const getDebtOrderAttachmentsPreviewUrl = (id) => {
	return getServiceUri() + 'debt-order/attachments' + '/preview/' + id + '/';
};
const updateDebtOrderAttachmentsData = (data) => {
	let filter = {name: data.fileName, description: data.description};
	rest.request(getServiceUri() + 'debt-order/attachments' + '/' + data.id, "PUT", filter);
};
const handleDebtOrderAttachmentsDelete = (id) => {
	rest.delete('debt-order/attachments', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};
const loadDebtOrderAttachmentsData = async (id) => {
	let filter = {and: true};
	filter['debt-order-attachments'] = {};
	filter['debt-order-attachments']['debtOrderId'] = id;
	return await rest.search('debt-order/attachments', filter)
}

export const form2dto = (formData, dto) => {
    if (formData.id != null) {
        dto.id = parseInt(formData.id)
    }
    customDebtOrderForm2Dto(formData, dto);
}

export const dto2form = (dto, token) => {
    let form = dto;
    customDebtOrderDto2Form(dto, form, token);
    return form;
}


class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	render() {
	    let DebtOrderForm = createFormComponent(debtOrderFields);
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={() => this.handleDontSave(this.props.id)}
			/>
			<DebtOrderForm ref={this.myRef} key={this.props.key} loadData={this.props.loadData} onSubmit={this.props.onSubmit} id={this.props.id} buttons={() => null} />
		</>
		)
	}
}

export const displayNewDebtOrderSidePanel = (gui, onFinish, formData) => {
	const readNewDebtOrderSidePanel = (onFinish) => (id) => {
        let uuid = uuidv4();
            gui.openSidePanel(<FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>)
    }
    let data;
    if (!formData) {
        data = buildEmptyObject(debtOrderFields);
        data.draft = true;
        save(data).then((response) => {
            readNewDebtOrderSidePanel(onFinish)(response)
        });
    } else {
        data = _.clone(formData);
        data.draft = true;
        form2dto(formData, data);
        if (formData.image) {
            if (!formData.image.isEmpty) {
                let base64Image = formData.image.url;
                fetch(base64Image)
                .then(res => res.blob())
                .then(blob => {
                    let faceMimeType = "image/png";
                    data.face = blob;
                    data.faceMimeType = faceMimeType;
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        data.image.url = reader.result;
                        save(data).then((response) => {
                            readNewDebtOrderSidePanel(onFinish)(response)
                        });
                    };
                    reader.readAsDataURL(blob);
                });
            } else {
                save(data).then((response) => {
                    readNewDebtOrderSidePanel(onFinish)(response)
                });
            }
        } else {
            save(data).then((response) => {
                readNewDebtOrderSidePanel(onFinish)(response)
            });
        }
    }
}

export const displayNewDebtOrderForm = (gui, onFinish, formData) => {
	const readNewDebtOrderForm = (onFinish) => (id) => {
        let uuid = uuidv4();
        return {
            uuid, view: () => <FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then((response) => onFinish(response)):update} id={id}/>, getContext: (getContext) && getContext
        };

    }
    let data;
    if (!formData) {
        data = buildEmptyObject(debtOrderFields);
        data.draft = true;
        save(data).then((response) => {
            gui.goTo(readNewDebtOrderForm(onFinish), response)
        });

    } else {
        data = _.clone(formData);
        data.draft = true;
        form2dto(formData, data);
        if (formData.image) {
            if (!formData.image.isEmpty) {
                let base64Image = formData.image.url;
                fetch(base64Image)
                .then(res => res.blob())
                .then(blob => {
                    let faceMimeType = "image/png";
                    data.face = blob;
                    data.faceMimeType = faceMimeType;
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        data.image.url = reader.result;
                        save(data).then((response) => {
                            gui.goTo(readNewDebtOrderForm(onFinish), response)
                        });
                    };
                    reader.readAsDataURL(blob);
                });
            } else {
                save(data).then((response) => {
                    gui.goTo(readNewDebtOrderForm(onFinish), response)
                });
            }
        } else {
            save(data).then((response) => {
                gui.goTo(readNewDebtOrderForm(onFinish), response)
            });
        }
    }
}

export const displayReadDebtOrderForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>
	};
}

export const displayReadDebtOrderSidePanel = (onFinish) => (id) => {
	let uuid = uuidv4();
	gui.openSidePanel(<FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>);
}

const buildEmptyObject = (fields) => {
	const empty = {};
	for (let i = 0; i < fields.length; i++) {
		let field = fields[i];
		switch (field.type) {
			case ("text"):
				empty[field.name] = "";
				break;
			case ("number"):
				empty[field.name] = "";
				break;
			case ("checkbox"):
				empty[field.name] = false;
				break;
			case ("timestampz"):
				empty[field.name] = '';
				break;
			case ("date"):
				empty[field.name] = null;
				break;
			case ("select"): // dynamic lists, loaded from the backend
				empty[field.name] = '';
				break;
			case ("list"): // static lists, hardcoded
				empty[field.name] = '';
				break;
			case ("password"):
				empty[field.name] = '';
				break;
            case ("image"):
                empty[field.name] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: '/public/avatar.png', isEmpty: true};
                break;
		}
	}
	return empty;
}

export const loadFormData = async (id) => {
    let token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	return await rest.read('debt-order', id).then(response => {
		let form = dto2form(response, token);
		return form;
	})
}

export const save = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['debt-order'].form2dto(data);
	form2dto(formData, dto);
	try {
        return rest.request(getServiceUri() + 'debt-order/new-document', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
				return response;
		});
    } catch (err) {
        alert(err);
    }
}

export const update = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['debt-order'].form2dto(data);
	form2dto(formData, dto)
	dto.draft = false;
	try {
        return rest.request(getServiceUri() + 'debt-order/edit-document', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated debtOrder`, "success");
		});
    } catch (err) {
        alert(err);
    }
}