import React from "react";
import AutocompleteDynamicListSelectorField from "../../widgets/AutocompleteDynamicListSelectorField";
import { createRecord, getRecordsOptions, readRecord } from "../../utils";
import { Section } from "../../../../auto/js/widgets/Section";
import { rest, t } from "../../../../auto/js/services";
import { whoami } from "../../../../auto/js/users/UserInfo";
import { formState } from "../../../../auto/js/forms/FormState";
import { displayCaseDataList } from "../../../../auto/js/lists/caseData/CaseDataList";
import { save, update } from "../../../../auto/js/forms/debtOrder/NewDebtOrderForm";

export const customDebtOrderForm2Dto = (formData, dto) => {
    if (formData.creditor) {
		rest.purge('creditor', formData.id).then(() => {
			Object.keys(formData.creditor).forEach(element => {
				let names = formData.creditor[element].split(' ');
				let lastname ="";
				let firstname = names[0];
				if (names.length > 1)
					lastname = names[names.length - 1];
				let data = {folderId: formData.id, recordId: element, firstname: firstname, lastname: lastname}
				rest.create('creditor', data);
			});
		})
		
	}
    if (formData.debtor) {
		rest.purge('debtor', formData.id).then(() => {
			Object.keys(formData.debtor).forEach(element => {
				let names = formData.debtor[element].split(' ');
				let lastname ="";
				let firstname = names[0];
				if (names.length > 1)
					lastname = names[names.length - 1];
				let data = {folderId: formData.id, recordId: element, firstname: firstname, lastname: lastname}
				rest.create('debtor', data);
			});
		})
	}
}

export const customDebtOrderDto2Form = (dto, form) => {

}

export const debtorComponentHook = (name, disabled) => {
    return <AutocompleteDynamicListSelectorField time={1000} readEntry={readRecord} createEntry={(data, callback, onCancel) => createRecord(data, callback, onCancel)} searchRequest={async (filter) => getRecordsOptions(filter)} name={name} readOnly={disabled} label={t`Debtor`} />
}

export const creditorComponentHook = (name, disabled) => {
    return <AutocompleteDynamicListSelectorField time={1000} readEntry={readRecord} createEntry={(data, callback, onCancel) => createRecord(data, callback, onCancel)} searchRequest={async (filter) => getRecordsOptions(filter)} name={name} readOnly={disabled} label={t`Creditor`} />
}

export const debtInformationComponentHook = (name, disabled) => {
    return <Section name={name} />
}

export const getContext = () => {
    return {
        "na": {
            "na": {
                submenu: {
                    ...(whoami().roles.includes('ALL') || whoami().roles.includes('Judge')) && {
                        "actions": {
                            options: {
                                    ...(whoami().roles.includes('ALL') || whoami().roles.includes('Judge')) &&
                                { new: { label: "Submit", do: (gui) => update(formState.getState().data).then(() => {
                                    displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["SUBMITTED"]});
                                }) }},
                            },
                            label: "Actions"
                        }},
                }
            },
            label: "New Cases",
            do: (gui) => displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["SUBMITTED"]})(gui)
        }
    }
}

export const idDisplay = (data) => {
    return false;
}