import 'date-fns';
import React from "react";

import LoadingOverlay from 'react-loading-overlay-ts';
import * as Yup from 'yup';


 
import "react-datepicker/dist/react-datepicker.css";
import "../pages/Form.css";
 
import { DebtOrderSummary } from './DebtOrderSummary';

import { memstore } from '../Memstore';
import { JugementSummary } from './JugementSummary';
import { RegistreOppositionSummary } from './RegistreOppositionSummary';
 
const buildRules = (fields) => {
    let rule = {};
    fields.forEach(element => {
        if(element.validation)
        rule[element.name] = element.validation;
        if (element.type === "box") {
        element.components.forEach(e => {
            if(e.validation)
            rule[ e.name] = e.validation;
        });
        }
    });
    return rule
}
 
 
const buildRows = (fields, data, readOnly, gui) => {
  const items = [];
  JSON.parse(data.data).documents.forEach(element => {
    switch(element.documentType) {
      case ("DEBT_ORDER"):
        let raObj = memstore.save(element.debtOrder);
        items.push(
          <div className={'col-md-12'}>
              <DebtOrderSummary readOnly={true} gui={gui} obj={raObj} columns={['debtReferenceNumber', 'amountOwed', 'debtDate', 'duPaymentDate']} footerElements= {['created', 'lastModified']} title={"Debt Order"}/>
          </div>
        )
        break;
      case ("JUGEMENT"):
        if (element.jugement.date) {
          let jObj = memstore.save(element.jugement);
          items.push(
            <div className={'col-md-12'}>
                <JugementSummary gui={gui} obj={jObj}/>
            </div>
          )
        }
        break;
      case ("REGISTRE_OPPOSITION"):
        let roObj = memstore.save(element.registreOpposition);
        items.push(
          <div className={'col-md-12'}>
              <RegistreOppositionSummary gui={gui} obj={roObj}/>
          </div>
        )
        break;
    }
 });
  return items;
};
 
export const createTimelineWidgetsComponent = (fields) => class TimelineWidgetsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef()
    this.state = {
      data: undefined,
      refreshSeq: 0,
      loading: true,
      loadingMessage: ''
    }
    props.loadData().then((initialValues) => this.setState({ data: initialValues, loading: false }));
    this.validationSchema = Yup.object().shape(Object.assign({}, buildRules(fields)));

  }
 
  refresh = () => {
    setState({ refreshSeq: refreshSeq + 1 });
  }

 
  onKeyDown = (event) => {
    if (event.keyCode === 13)
      event.preventDefault();
  }
 
  render() {
    return (
 
      <LoadingOverlay
        active={this.state.loading}
        spinner
        text={this.state.loadingMessage}
      >
        { this.state.data && (   
        <div>
            { buildRows(fields, this.state.data, this.props.readOnly, this.props.gui) }
        </div>
        )}
      </LoadingOverlay>
    );
  }
}