import React, { Component } from "react";

import { rest, t } from "../services";
import { buildEmptyObject, createFormComponent } from ".";
;
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { createTableComponent } from "./TableComponent";
import { parentsSearchFormfields, transformFormData, recordsListFields } from "../../../main/js/forms/records/RecordsFormCommon";
import { DraggableFilterDialog } from "./DraggableFilterComponentUpgrade";
import { formState } from "../forms/FormState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointLeft } from "@fortawesome/free-regular-svg-icons";

import { OPEN_CREATE_OR_SEARCH } from "../events/Gui";
import { getDjServicesUrl } from "../../../main/js/utils";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ maxWidth: '50%', width: '50%' }} />
    </Draggable>
  );
}
function PaperListComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-list"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ maxWidth: '50%', width: '50%' }} />
    </Draggable>
  );
}

class CreateOrSearchComponentClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            listOpen: false,
            formData: undefined,
            transformedData: null,
        };
        this.createCallback = null;
        this.onCancel = null;

        // Subscribe to OPEN_CREATE_OR_SEARCH
        OPEN_CREATE_OR_SEARCH.subscribe((eventData) => {
            const data = eventData.data;
            if (data) this.setState({ formData: data });
            this.setState({ open: true });
            this.setCreateCallback(eventData.callback);
            this.setCreateCancel(eventData.cancel);
        });
    }

    setCreateCancel = (cancel) => {
        this.onCancel = cancel;
    }

    setCreateCallback = (callback) => {
        this.createCallback = callback;
    }

    onResultSelected = (id) => {
        if (this.createCallback !== null) {
            this.createCallback(id);
        }
    }

    handleClose = () => {
        this.setState({ listOpen: false, open: false });
        if (this.onCancel !== null)
            this.onCancel();
    }

    handleListClose = () => {
        this.setState({ listOpen: false });
    }

    submit = (formData) => {
        const columns = transformFormData(formData);
        this.setState({ formData, transformedData: columns, listOpen: true });
        return columns;
    }

    openDraggableComponent = () => {
        this.setState({ open: true });
    }

    getButtons = (data) => {
        return (
            <>
                <button style={{ minWidth: '5rem', position: 'fixed', right: '-190px', top: '5px' }} type="submit"> {t`Find`} </button>
                <button style={{ minWidth: '5rem', position: 'fixed', right: '40px', top: '5px' }} type="button" onClick={async () => this.createRecord(data).then((response) => {this.createCallback(response);this.setState({open: false})})} className="reject-button"> {t`Create`} </button>
            </>
        );
    }

    buildFormData = async () => {
        return this.state.formData;
    }

    createRecord = async (data) => {
        if (data.exactBirthdate !== null)
            data.birthdate = data.exactBirthdate;
        return rest.create('records', data, getDjServicesUrl());
    }

    getVitalRecordFromSearch = async (filter) => {
        return await rest.request(getDjServicesUrl() + "records/advanced-search", "POST", filter);
    }

    countData = async (filter) => {
        return await rest.request(getDjServicesUrl() + "records/count/advanced-search", "POST", filter);
    }

    filterData = (DefaultRows, token) => {
        const newRows = [];
        for (let i in DefaultRows) {
            let faceUrl = null;
            let row = DefaultRows[i];
            if (row.faceId != null) {
                faceUrl = getDjServicesUrl() + "face/image/" + row.faceId + "/" + token;
            }
            row.image = faceUrl ? faceUrl : "/public/avatar.png";
            let date = row.birthdate;
            if (date !== null) row.birthdate = date[2] + '-' + date[1] + '-' + date[0];
            newRows.push(row);
        }
        return newRows;
    }

    buildData = async (query) => {
        const token = await rest.getToken(getDjServicesUrl() + 'token/get-auth-code');
        let filter = { ...query };
        let data;
        filter["records"] = { inactiveList: [false] };
        filter["orderBy"] = "ID";
        filter.orderDirection = null;
        filter["filter"] = query.search;
        filter["query"] = this.state.transformedData;
        filter.offset = query.page * query.pageSize;
        return await this.getVitalRecordFromSearch(filter).then(response => {
            data = this.filterData(response, token);
            return this.countData(filter).then((count) => ({ data: data, totalCount: count, page: query.page }));
        });
    }

    getTableActions = () => {
        let actions = [];
        actions.push(
            {
                icon: () => <FontAwesomeIcon icon={faHandPointLeft} />,
                tooltip: t`Choose`,
                onClick: (event, rowData) => {
                    this.onResultSelected(rowData.id);
                    this.setState({ listOpen: false, open: false });
                }
            }
        );
        return actions;
    }

    render() {
        const { open, listOpen } = this.state;
        const SearchForm = createFormComponent((this.props.parentsSearchFormfields) ? this.props.parentsSearchFormfields : parentsSearchFormfields);
        const CivilRecordsList = createTableComponent(recordsListFields);

        return (
            <>
                {open && <DraggableFilterDialog open={open} onClose={this.handleClose} title={t("Create or Filter your search")} paperComponent={PaperComponent}
                    description={""} children={
                        <SearchForm loadData={
                            async () => {
                                if (this.props.buildEmptyObject !== undefined) {
                                    return this.props.buildEmptyObject((this.props.parentsSearchFormfields) ? this.props.parentsSearchFormfields : parentsSearchFormfields);
                                } else {
                                    if (this.state.formData)
                                        return this.buildFormData();
                                    else
                                        return buildEmptyObject((this.props.parentsSearchFormfields) ? this.props.parentsSearchFormfields : parentsSearchFormfields);
                                }
                            }
                        } buttons={this.getButtons} onSubmit={this.submit} />
                    } />}
                {listOpen && <DraggableFilterDialog open={listOpen} onClose={this.handleListClose} title={t("Record list")} paperComponent={PaperListComponent} description={t("You can choose the record you want to export")} children={<CivilRecordsList loadData={async (query) => this.buildData(query)} columns={recordsListFields} actions={this.getTableActions()} />} />}
            </>
        );
    }
}

export default CreateOrSearchComponentClass;

