import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createTimelineWidgetsComponent } from "../widgets/TimelineWidgetsComponent";
import { rest } from "../../../auto/js/services";
import { loadCaseData, printTemplate } from "../utils";
import { CaseComponent } from "../widgets/CaseComponent";
import { whoami } from "../../../auto/js/users/UserInfo";
import { cleanContextualOptions, setContextualOptions } from "../../../auto/js/widgets";
import { displayCaseDataList } from "../../../auto/js/lists/caseData/CaseDataList";
import { displayReadRegistreAffectationSidePanel } from "../../../auto/js/forms/registreAffectation/NewRegistreAffectationForm";


class TimelineComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
    }

	loadTimelineData = async (id, gui) => {
		return await rest.read('read-case-data', id).then(response => {
			this.setContext(JSON.parse(response.data), id, gui)
			return response;
		});
	}
	
	setContext = async (data, id, gui) => {
		let tagFilter = {};
		tagFilter["case-tags"] = {caseId: id};
		rest.search('case-tags', tagFilter).then((response) => {
			response.forEach(element => {
				if (element.key === "AFFAIRE_STATUS")
					if (element.value === "SUBMITTED") {
						let doc = data.documents.filter((doc) => doc.documentType === "DEBT_ORDER")[0];
						let affDoc = data.documents.filter((doc) => doc.documentType === "REGISTRE_AFFECTATION")[0];
						if (doc)
							setContextualOptions({
								"na": {
									"na": {
										submenu: {
											"actions": {
												label: "Actions",
												options: {
													
														"accept": {
															label: "Accept",
															do: () => investigate(id).then(() => {
																displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["JUDGED"]})
																cleanContextualOptions({"aj":{"aj":{}}})
															})
														},
														"refusal": {
															label: "Refusing to accept the statement",
															do: () => refusal(id).then(() => {
																displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["CLOSED"]})
																cleanContextualOptions({"ac":{"ac":{}}})
															})
														},
														"returnRefusal": {
															label: "Return to accept the statement",
															do: () => returnRefusal(id).then(() => {
																displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["CLOSED"]})
																cleanContextualOptions({"ac":{"ac":{}}})
															})
														},
														"withoutAction": {
															label: "Leaving without action to accept the statement",
															do: () => withoutAction(id).then(() => {
																displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["CLOSED"]})
																cleanContextualOptions({"ac":{"ac":{}}})
															})
														},
														/* "affecter": {
															label: "Passing the case to a judge",
															do: (gui) => displayReadRegistreAffectationSidePanel(gui, affDoc.id,()=>{
																displayCaseDataTimeline(gui, id);
																gui.closeSidePanel();
															})
														
														} */

												}
											}
										}
									}
								}	
							})
					}

	
			});
		})
	}


	render() {
		let CaseTimeline = createTimelineWidgetsComponent([]);

		return (
		<>
			<CaseComponent name={name} disabled={true} loadData={() => loadCaseData(this.props.id)} />
			<CaseTimeline gui={this.props.gui} ref={this.myRef} key={this.props.key} loadData={async () => this.loadTimelineData(this.props.id, this.props.gui)} />
		</>
		)
	}

}


export const displayCaseDataTimeline = (gui, id)=> {
		 const readCaseDataTimeline = (onFinish) => (id) => {
		let uuid = uuidv4();
		return {
			uuid, view: () => <TimelineComponent key={uuid} gui={gui} id={id} onFinish={onFinish}/>
		};
	}
	gui.goTo(readCaseDataTimeline(), id) 
}

const investigate = async (id) => {
	rest.read('forms/accepted', id).then(async accepted => {
		printTemplate(accepted)
	})
}

const refusal = async (id) => {
	rest.read('forms/refuse', id).then(async refuse => {
		printTemplate(refuse)
	})
}
const returnRefusal = async (id) => {
	rest.read('forms/return', id).then(async rf => {
		printTemplate(rf)
	})
}
const withoutAction = async (id) => {
	rest.read('forms/without-action', id).then(async wa => {
		printTemplate(wa)
	})
}