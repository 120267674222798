import React from "react";
import {v4 as uuidv4} from 'uuid';
import _ from 'lodash';
import { CAN_I_SWITCH, DELETE_ATTACHMENT_EVENT } from "../../../../auto/js/events/Gui";
import { pojoMetadata, getDjServicesUrl, getServiceUri } from "../../../../auto/js/metadata";
import { rest, t } from "../../../../auto/js/services";
import { showNotification, swapObject } from "../../../../auto/js/utils";
import { AlertDialog } from "../../../../auto/js/widgets/Dialogs";
import { createFormComponent } from "../../../../auto/js/widgets/FormComponent";
import { customRecordsForm2Dto, customRecordsDto2Form } from "../../../../main/js/forms/records/RecordsFormCommon.jsx";
import * as Yup from 'yup';
import { GeoDataComponent } from "../../../../auto/js/widgets/GeoDataComponent";


export const recordsFields = [
    {
        name: "id",
        label: "id",
        type: "number",
        x: "1",
        y: "2",
        layout: "col-md-6",
        disabled: true
    },
    {
        name: "firstname",
        label: "firstname",
        type: "text",
        x: "3",
        y: "3",
        layout: "col-md-4",
        disabled: true
    },
    {
        name: "othernames",
        label: "othernames",
        type: "text",
        x: "2",
        y: "3",
        layout: "col-md-4",
        disabled: true
    },
    {
        name: "lastname",
        label: "lastname",
        type: "text",
        x: "1",
        y: "3",
        layout: "col-md-4",
        disabled: true
    },
    {
        name: "idNumber",
        label: "idNumber",
        type: "text",
        x: "1",
        y: "5",
        layout: "col-md-6",
        disabled: true
    },
    {
        name: "birthdate",
        label: "birthdate",
        type: "date",
        x: "1",
        y: "5",
        layout: "col-md-6",
        disabled: true
    },

    {
        name: "fatherName",
        label: "fatherName",
        type: "text",
        x: "1",
        y: "4",
        layout: "col-md-6",
        disabled: true
    },
    {
        name: "email",
        label: "email",
        type: "text",
        x: "1",
        y: "7",
        layout: "col-md-6",
        disabled: true
    },
    {
        name: "phoneNumber",
        label: "phoneNumber",
        type: "text",
        x: "2",
        y: "7",
        layout: "col-md-6",
        disabled: true
    },
    {
        name: "cinFile",
        label: "cinFile",
        type: "file",
        x: 1,
        y: 8,
        layout: "col-md-12",
        uploadUrl: (id) => getRecordCintUploadUrl(id),
        previewUrl: (id) => getRecordCintPreviewUrl(id),
        loadData: async (id) => loadRecordCintData(id),
        handleDelete:(id) => handleRecordCintDelete(id),
        handleClick: (id) => handleRecordCintClick(id),
        updateFileData: (data) => updateRecordCintData(data)
    }
]

const getRecordCintUploadUrl = (id) => {
    return getDjServicesUrl() + 'records/cin-file' + '/' + id;
};
const handleRecordCintClick = async (id) => {
	const token = await rest.getToken(getDjServicesUrl() + 'token/get-auth-code');
	window.location = getDjServicesUrl() + 'records/cin-file' + '/' + id + '/' + token;
};
const getRecordCintPreviewUrl = (id) => {
	return getDjServicesUrl() + 'records/cin-file' + '/preview/' + id + '/';
};
const updateRecordCintData = (data) => {
	let filter = {name: data.fileName, description: data.description};
	rest.request(getDjServicesUrl() + 'records/cin-file' + '/' + data.id, "PUT", filter);
};
const handleRecordCintDelete = (id) => {
	rest.delete('records/cin-file', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};
const loadRecordCintData = async (id) => {
	let filter = {and: true};
	filter['records-cin-file'] = {};
	filter['records-cin-file']['recordsId'] = id;
	return rest.search('records/cin-file', filter, getDjServicesUrl())
}

export const form2dto = (formData, dto) => {
    if (formData.id != null) {
        dto.id = parseInt(formData.id)
    }
    if (formData.image !== undefined && !formData.image.isEmpty) {
        let base64Image = formData.image.url;
        let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'));
        let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
        dto.face = base64;
        dto.faceMimeType = faceMimeType;
    }
    if (formData.birthdate != null && typeof(formData.birthdate) != 'string') {
        let birthdateAsDate = new Date(formData.birthdate);
        dto.birthdate = birthdateAsDate.getFullYear() + "-" + ('0' + (birthdateAsDate.getMonth() + 1)).slice(-2) + "-" + ('0' + birthdateAsDate.getDate()).slice(-2);
    }
    customRecordsForm2Dto(formData, dto);
}

export const dto2form = (dto) => {
    let form = dto;
    if (dto.face !== undefined && dto.face != null) {
        let imageContent = "data".concat('image/png', ";base64,", dto.face);
        form.image = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (imageContent != null)?imageContent: '/public/avatar.png', isEmpty: (imageContent == null)};
    } else
        form.image = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: '/public/avatar.png', isEmpty: true};

    customRecordsDto2Form(dto, form);
    return form;
}

const getButtons = (id, onFinish) => {
    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    
                        <button style={{ minWidth: '5rem' }} className={'reject-button'} type="submit">
                            {t`Submit`}
                        </button>
                    
                </div>
            </div>
        </>
    )
}

class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	render() {
	    let RecordsForm = createFormComponent(recordsFields);
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={() => this.handleDontSave(this.props.id)}
			/>
			<RecordsForm ref={this.myRef} key={this.props.key} loadData={this.props.loadData} onSubmit={this.props.onSubmit} id={this.props.id} buttons={() => getButtons(this.props.id, this.props.onFinish)} />
		</>
		)
	}
}

export const displayNewRecordsForm = (gui, formData) => {
	const readNewRecordsForm = (onFinish) => (id) => {
        let uuid = uuidv4();
        return {
            //uuid, view: () => <FormComponent key={uuid} loadData={async () => buildEmptyObject(recordsFields)} onSubmit={(onFinish)?(data) => save(data).then(() => onFinish()):save}/>
            uuid, view: () => <FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>

        };
    }
    let data;
    if (!formData) {
        data = buildEmptyObject(recordsFields);
        data.draft = true;
        save(data).then((response) => {
            gui.goTo(readNewRecordsForm(), response)
        });
    } else {
        data = _.clone(formData);
        data.draft = true;
        form2dto(formData, data);
        if (formData.image) {
            if (!formData.image.isEmpty) {
                let base64Image = formData.image.url;
                fetch(base64Image)
                .then(res => res.blob())
                .then(blob => {
                    let faceMimeType = "image/png";
                    data.face = blob;
                    data.faceMimeType = faceMimeType;
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        data.image.url = reader.result;
                        save(data).then((response) => {
                            gui.goTo(readNewRecordsForm(), response)
                        });
                    };
                    reader.readAsDataURL(blob);
                });
            } else {
                save(data).then((response) => {
                    gui.goTo(readNewRecordsForm(), response)
                });
            }
        } else {
            save(data).then((response) => {
                gui.goTo(readNewRecordsForm(), response)
            });
        }
    }
}

export const displayReadRecordsForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>
	};
}

const buildEmptyObject = (fields) => {
	const empty = {};
	for (let i = 0; i < fields.length; i++) {
		let field = fields[i];
		switch (field.type) {
			case ("text"):
				empty[field.name] = "";
				break;
			case ("number"):
				empty[field.name] = "";
				break;
			case ("checkbox"):
				empty[field.name] = false;
				break;
			case ("timestampz"):
				empty[field.name] = '';
				break;
			case ("date"):
				empty[field.name] = null;
				break;
			case ("select"): // dynamic lists, loaded from the backend
				empty[field.name] = '';
				break;
			case ("list"): // static lists, hardcoded
				empty[field.name] = '';
				break;
			case ("password"):
				empty[field.name] = '';
				break;
            case ("image"):
                empty[field.name] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: '/public/avatar.png', isEmpty: true};
                break;
		}
	}
	return empty;
}

export const loadFormData = async (id) => {
	return await rest.read('records', id, getDjServicesUrl()).then(response => {
		let form = dto2form(response);
		return form;
	})
}

const save = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['records'].form2dto(data);
	form2dto(formData, dto);
	try {
        return rest.request(getDjServicesUrl() + 'records/new-document', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
				return response;
		});
    } catch (err) {
        alert(err);
    }
}

const update = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['records'].form2dto(data);
	form2dto(formData, dto)
	try {
        return rest.request(getDjServicesUrl() + 'records/edit-document', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated records`, "success");
		});
    } catch (err) {
        alert(err);
    }
}