export const pojoMetadata = {
			'registre-affectation': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "caseStatus", name: "case_status", editable: true, resizable: true, type: "number" },
			   	{ key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
			   	{ key: "substitutIdCard", name: "substitut_id_card", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.substitutIdCard == "")
					data.substitutIdCard = null;
				return data
			}
		},
		'case-data': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "caseId", name: "case_id", editable: true, resizable: true, type: "number" },
			   	{ key: "data", name: "data", editable: true, resizable: true, type: "json" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'debt-order': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "amountOwed", name: "amount_owed", editable: true, resizable: true, type: "text" },
			   	{ key: "attachments", name: "attachments", editable: true, resizable: true, type: "text" },
			   	{ key: "debtDate", name: "debt_date", editable: true, resizable: true, type: "date" },
			   	{ key: "debtReferenceNumber", name: "debt_reference_number", editable: true, resizable: true, type: "text" },
			   	{ key: "debtType", name: "debt_type", editable: true, resizable: true, type: "number" },
			   	{ key: "duPaymentDate", name: "du_payment_date", editable: true, resizable: true, type: "date" },
			   	{ key: "paymentReceiptFile", name: "payment_receipt_file", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.amountOwed == "")
					data.amountOwed = null;
				if(data.debtDate != null && data.debtDate instanceof Date)
					data.debtDate = data.debtDate.getFullYear() + "-" + ('0' + (data.debtDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.debtDate.getDate()).slice(-2);
				if(data.debtReferenceNumber == "")
					data.debtReferenceNumber = null;
				if(data.duPaymentDate != null && data.duPaymentDate instanceof Date)
					data.duPaymentDate = data.duPaymentDate.getFullYear() + "-" + ('0' + (data.duPaymentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.duPaymentDate.getDate()).slice(-2);
				return data
			}
		},
		'address': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "areaId", name: "area_id", editable: true, resizable: true, type: "text" },
			   	{ key: "recordsId", name: "records_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.areaId == "")
					data.areaId = null;
				return data
			}
		},
		'records': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "face", name: "face", editable: true, resizable: true, type: "blob" },
			   	{ key: "fatherName", name: "father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "idNumber", name: "id_number", editable: true, resizable: true, type: "text" },
			   	{ key: "idType", name: "id_type", editable: true, resizable: true, type: "number" },
			   	{ key: "lastname", name: "lastname", editable: true, resizable: true, type: "text" },
			   	{ key: "othernames", name: "othernames", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.fatherName == "")
					data.fatherName = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.idNumber == "")
					data.idNumber = null;
				if(data.lastname == "")
					data.lastname = null;
				if(data.othernames == "")
					data.othernames = null;
				return data
			}
		},
		'i18n': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "domainId", name: "domain_id", editable: true, resizable: true, type: "text" },
			   	{ key: "localeId", name: "locale_id", editable: true, resizable: true, type: "text" },
			   	{ key: "messageId", name: "message_id", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.domainId == "")
					data.domainId = null;
				if(data.localeId == "")
					data.localeId = null;
				if(data.messageId == "")
					data.messageId = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
		'e-id-mtlb-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "eIdMtlbId", name: "e_id_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'registre-opposition': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "attachments", name: "attachments", editable: true, resizable: true, type: "text" },
			   	{ key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
			   	{ key: "judgmentDate", name: "judgment_date", editable: true, resizable: true, type: "date" },
			   	{ key: "judgmentNumber", name: "judgment_number", editable: true, resizable: true, type: "number" },
			   	{ key: "person", name: "person", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.judgmentDate != null && data.judgmentDate instanceof Date)
					data.judgmentDate = data.judgmentDate.getFullYear() + "-" + ('0' + (data.judgmentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.judgmentDate.getDate()).slice(-2);
				return data
			}
		},
		'debtor': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
			   	{ key: "lastname", name: "lastname", editable: true, resizable: true, type: "text" },
			   	{ key: "othernames", name: "othernames", editable: true, resizable: true, type: "text" },
			   	{ key: "recordId", name: "record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.firstname == "")
					data.firstname = null;
				if(data.lastname == "")
					data.lastname = null;
				if(data.othernames == "")
					data.othernames = null;
				return data
			}
		},
		'e-id-mtlb': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "documentId", name: "document_id", editable: true, resizable: true, type: "text" },
			   	{ key: "firstName", name: "first_name", editable: true, resizable: true, type: "text" },
			   	{ key: "idCardNumber", name: "id_card_number", editable: true, resizable: true, type: "number" },
			   	{ key: "lastName", name: "last_name", editable: true, resizable: true, type: "text" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "password", name: "password", editable: true, resizable: true, type: "password" },
			],
			form2dto: (data) => {
				if(data.documentId == "")
					data.documentId = null;
				if(data.firstName == "")
					data.firstName = null;
				if(data.lastName == "")
					data.lastName = null;
				if(data.password == "")
					data.password = null;
				return data
			}
		},
		'case-tags': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "caseId", name: "case_id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.key == "")
					data.key = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
		'geo-data': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "areaId", name: "area_id", editable: true, resizable: true, type: "text" },
			   	{ key: "disabled", name: "disabled", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "flag", name: "flag", editable: true, resizable: true, type: "blob" },
			   	{ key: "flagMimeType", name: "flag_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "parentId", name: "parent_id", editable: true, resizable: true, type: "number" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.areaId == "")
					data.areaId = null;
				if(data.flagMimeType == "")
					data.flagMimeType = null;
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'creditor': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
			   	{ key: "lastname", name: "lastname", editable: true, resizable: true, type: "text" },
			   	{ key: "othernames", name: "othernames", editable: true, resizable: true, type: "text" },
			   	{ key: "recordId", name: "record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.firstname == "")
					data.firstname = null;
				if(data.lastname == "")
					data.lastname = null;
				if(data.othernames == "")
					data.othernames = null;
				return data
			}
		},
		'jugement': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "date", name: "date", editable: true, resizable: true, type: "date" },
			   	{ key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
			   	{ key: "numeroCopie", name: "numero_copie", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.date != null && data.date instanceof Date)
					data.date = data.date.getFullYear() + "-" + ('0' + (data.date.getMonth() + 1)).slice(-2) + "-" + ('0' + data.date.getDate()).slice(-2);
				return data
			}
		},
		'user-preferences': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.key == "")
					data.key = null;
				if(data.userId == "")
					data.userId = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
}

export const buildEmptyObject = (entity) => {
	var columns = new Object();
	let fields = pojoMetadata[entity].columns
	for (let col in fields) {		
		if(fields[col].type == 'text'){
			columns[fields[col].key] = "";
		}else if(fields[col].type=='checkbox'){
			columns[fields[col].key] = false;
		}else if(fields[col].type=='datetime-local'){
			columns[fields[col].key] = "";
		}
		else if(fields[col].type=='autocomplete'){
			columns[fields[col].key] = null;
		}
	 }
	return columns
};

export const getEntityIdCol = (entity) => {
	return pojoMetadata[entity].columns[0].key;
}

export const getRowId = (entity, row) => {
	return row[getEntityIdCol(entity)];
}

